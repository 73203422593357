<template>
	<div>
		<!-- 内容 -->
		<div class="contain flex">
			<div class="page-content">
				<div class="tabContent">
					<div class="title-bar">招考报名 > {{info.name}}</div>
					<div class="title">{{info.name}}</div>
					<div class="sub-title">发布时间：{{utils.stringToDate(info.createTime)}}　来源：临海人才网</div>
					<div class="content" v-html="decodeURI(info.content)"></div>
					<div class="btn-bm" @click="usertype == 1?wybm():ts()">我要报名</div>
				</div>
			</div>

		</div>

	</div>
</template>
<script>
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import {
		GetExamModel,
	} from "@/api/home";
	export default {
		data() {
			return {
				info: {},
				usertype: 0,
				userinfo: "",
			};
		},
		created() {
			var id = this.$route.query.id;
			if (!isnull(id)) {
				this.Getinfo(id);
			}
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
				this.usertype = this.userinfo.userType;
			}
		},
		methods: {
			ts(){
				this.$message.error("请先登录个人用户");
			},
			wybm() {
				this.$router.push("zhaokaosumbit?id=" + this.info.id)
			},
			Getinfo(id) {
				GetExamModel({
					id: id
				}).then((res) => {
					if (res.success) {
						this.info = res.data;
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.page-content {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;

		::v-deep .el-tabs__header {
			margin: 0;
		}

		::v-deep .el-tabs__item {
			padding: 0 80px;
		}
	}

	.tabContent {
		background: #FFFFFF;
		padding: 20px;

	}

	.title-bar {
		padding: 8px 20px;
		background-color: rgba(25, 144, 255, 0.1);
		font-size: 13px;
		color: #101010;
		font-weight: bold;
	}

	.title {
		color: #000000;
		font-size: 18px;
		text-align: center;
		margin: 20px 0;
	}

	.sub-title {
		color: rgba(137, 137, 137, 1);
		font-size: 14px;
		text-align: center;
		padding-bottom: 10px;
		border-bottom: 1px solid #eee;
	}

	.content {
		padding: 16px 8px;
		font-size: 14px;
		line-height: 180%;
	}

	.btn-show {
		font-size: 14px;
		font-weight: bold;
	}

	.file-list {
		padding: 0 20px;

		div {
			font-size: 14px;
			color: #1990ff;
		}
	}

	.foot {
		font-size: 14px;
		text-align: right;
	}

	.btn-bm {
		width: 150px;
		margin: 0 auto;
		text-align: center;
		background: #eee;
		border: #ccc;
		height: 40px;
		line-height: 40px;
		margin-top: 80px;
		cursor: pointer;
	}
</style>
